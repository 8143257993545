import { MetricsPanelCtrl } from 'grafana/app/plugins/sdk';
import * as moment from 'moment';
import $ from 'jquery';
import 'jquery-datetimepicker';
//import 'bootstrap-datetimepicker';
//import 'jquery-ui';

//import * as $ from 'jquery';
//import $ from 'jquery';
//import 'jquery-ui';
//import 'jquery-ui/ui/widgets/datepicker';
//import * as jQueryUI from 'jquery-ui';
import _ from 'lodash';
import appEvents from 'grafana/app/core/app_events';

interface DowntimeListInterface {
  name: string;
  id: string;
  groups: GroupInterface[];
}

interface GroupInterface {
  name: string;
  reasons: ReasonInterface[];
}
interface ReasonInterface {
  text: string;
  color: string;
}

// Expects a template with:
// <div class="canvas-spot"></div>
export class CanvasPanelCtrl extends MetricsPanelCtrl {
  data: any;
  mouse: any;
  $tooltip: any;
  wrap: any;
  canvas: any;
  modalContainer: HTMLDivElement;
  context: any;
  _devicePixelRatio: number;
  selectedStatus: string;
  selectedRange: any;
  $parent: any;
  isSingleTarget: boolean;
  modalScope: any;
  reasonsDataSource: any[];
  downtimeLists: DowntimeListInterface[];

  /** @ngInject */
  constructor($scope, $injector, private backendSrv, private $http, public templateSrv, public $compile) {
    super($scope, $injector);

    this.selectedStatus = null;
    this.data = null;
    this.mouse = {
      position: null,
      down: null,
    };
    this.$tooltip = $('<div class="graph-tooltip">');

    this.events.on('panel-initialized', this.onPanelInitialized.bind(this));
    this.events.on('refresh', this.onRefresh.bind(this));
    this.events.on('render', this.onRender.bind(this));

    this._devicePixelRatio = 1;
    if (window.devicePixelRatio !== undefined) {
      this._devicePixelRatio = window.devicePixelRatio;
    }
  }

  onPanelInitialized() {
    //console.log("onPanelInitalized()");
    this.render();
  }

  onRefresh() {
    //console.log("onRefresh()");
    this.render();
  }

  // Typically you will override this
  onRender() {
    if (!this.context) {
      console.log('No context!');
      return;
    }
    console.log('canvas render', this.mouse);

    const rect = this.wrap.getBoundingClientRect();

    const height = Math.max(this.height, 100);
    const width = rect.width;
    this.canvas.width = width;
    this.canvas.height = height;

    const centerV = height / 2;

    const ctx = this.context;
    ctx.lineWidth = 1;
    ctx.textBaseline = 'middle';

    let time = '';
    if (this.mouse.position !== null) {
      time = this.dashboard.formatDate(moment(this.mouse.position.ts));
    }

    ctx.fillStyle = '#999999';
    ctx.fillRect(0, 0, width, height);
    ctx.fillStyle = '#111111';
    ctx.font = '24px "Open Sans", Helvetica, Arial, sans-serif';
    ctx.textAlign = 'left';
    ctx.fillText('Mouse @ ' + time, 10, centerV);

    if (this.mouse.position !== null) {
      if (this.mouse.down !== null) {
        const xmin = Math.min(this.mouse.position.x, this.mouse.down.x);
        const xmax = Math.max(this.mouse.position.x, this.mouse.down.x);

        // Fill canvas using 'destination-out' and alpha at 0.05
        ctx.globalCompositeOperation = 'destination-out';
        ctx.fillStyle = 'rgba(255, 255, 255, 0.6)';
        ctx.beginPath();
        ctx.fillRect(0, 0, xmin, height);
        ctx.fill();

        ctx.beginPath();
        ctx.fillRect(xmax, 0, width, height);
        ctx.fill();
        ctx.globalCompositeOperation = 'source-over';
      } else {
        ctx.strokeStyle = '#111';
        ctx.beginPath();
        ctx.moveTo(this.mouse.position.x, 0);
        ctx.lineTo(this.mouse.position.x, height);
        ctx.lineWidth = 3;
        ctx.stroke();

        ctx.beginPath();
        ctx.moveTo(this.mouse.position.x, 0);
        ctx.lineTo(this.mouse.position.x, height);
        ctx.strokeStyle = '#e22c14';
        ctx.lineWidth = 2;
        ctx.stroke();
      }
    }
  }

  clearTT() {
    this.$tooltip.detach();
  }

  getMousePosition(evt) {
    const elapsed = this.range.to - this.range.from;
    const rect = this.canvas.getBoundingClientRect();
    let x = evt.offsetX; // - rect.left;
    let y = evt.clientY - rect.top;

    if (!x || !y) {
      if (evt.targetTouches.length > 0) {
        x = evt.targetTouches[0].pageX - rect.left;
        y = evt.targetTouches[0].pageY - rect.top;
      } else if (evt.changedTouches.length > 0) {
        x = evt.changedTouches[0].pageX - rect.left;
        y = evt.changedTouches[0].pageY - rect.top;
      }
    }

    const ts = this.range.from + elapsed * (x / parseFloat(rect.width));

    return {
      x: x,
      y: y,
      yRel: y / parseFloat(rect.height),
      ts: ts,
      evt: evt,
    };
  }

  onGraphHover(evt, showTT, isExternal) {
    console.log('HOVER', evt, showTT, isExternal);
  }

  onMouseClicked(where, event) {
    console.log('CANVAS CLICKED', where, event);
    this.render();
  }

  onMouseSelectedRange(range, event) {
    console.log('CANVAS Range', range, event);
  }

  onMouseUp(evt) {
    this.$tooltip.detach();
    const up = this.getMousePosition(evt);
    if (up === null) {
      return;
    }

    if (this.mouse.down !== null) {
      if (up.x === this.mouse.down.x && up.y === this.mouse.down.y) {
        this.mouse.position = null;
        this.mouse.down = null;

        this.isSingleTarget = true;
        this.onMouseClicked(up, evt);
      } else {
        const min = Math.min(this.mouse.down.ts, up.ts);
        const max = Math.max(this.mouse.down.ts, up.ts);
        const range = { from: moment.utc(min), to: moment.utc(max) };

        // let s = `from [${range.from._d}] to [${range.to._d}]`;

        // this.doRequest({url: 'https://localhost/'}).then(r => console.log(r));

        this.isSingleTarget = false;
        this.mouse.position = up;
        if (this.panel.isZoomOn) {
          this.onMouseSelectedRange(range, evt);
        } else {
          console.log('mouse up');
          this.showDowntimeDialog(range);
        }
      }
    }
    this.mouse.down = null;
    this.mouse.position = null;
  }

  onMouseDown(evt) {
    this.mouse.down = this.getMousePosition(evt);
  }

  onMouseMove(evt) {
    if (!this.range) {
      return; // skip events before we have loaded
    }

    this.mouse.position = this.getMousePosition(evt);
    if (!this.mouse.position) {
      return;
    }
    const info = {
      pos: {
        pageX: evt.pageX,
        pageY: evt.pageY,
        x: this.mouse.position.ts,
        y: this.mouse.position.y,
        panelRelY: this.mouse.position.yRel,
        panelRelX: this.mouse.position.xRel,
      },
      evt: evt,
      panel: this.panel,
    };
    appEvents.emit('graph-hover', info);
    if (this.mouse.down !== null) {
      $(this.canvas).css('cursor', 'col-resize');
    }
  }

  onMouseOut(evt) {
    if (this.mouse.down === null) {
      this.mouse.position = null;
      this.onRender();
      this.$tooltip.detach();
      appEvents.emit('graph-hover-clear');
    }
  }

  link(scope, elem, attrs, ctrl) {
    this.wrap = elem.find('.canvas-spot')[0];
    this.canvas = document.createElement('canvas');
    this.wrap.appendChild(this.canvas);

    $(this.canvas).css('cursor', 'pointer');
    $(this.wrap).css('width', '100%');

    //  console.log( 'link', this );

    this.context = this.canvas.getContext('2d');
    this.canvas.addEventListener(
      'mousemove',
      evt => {
        this.onMouseMove(evt);
      },
      false
    );
    this.canvas.addEventListener(
      'touchmove',
      evt => {
        this.onMouseMove(evt);
      },
      false
    );

    this.canvas.addEventListener(
      'mouseout',
      evt => {
        this.onMouseOut(evt);
      },
      false
    );
    this.canvas.addEventListener(
      'touchcancel',
      evt => {
        this.onMouseOut(evt);
      },
      false
    );

    this.canvas.addEventListener(
      'mousedown',
      evt => {
        this.onMouseDown(evt);
      },
      false
    );
    this.canvas.addEventListener(
      'touchstart',
      evt => {
        this.onMouseDown(evt);
      },
      false
    );

    this.canvas.addEventListener(
      'mouseup',
      evt => {
        this.onMouseUp(evt);
      },
      false
    );
    this.canvas.addEventListener(
      'touchend',
      evt => {
        this.onMouseUp(evt);
        this.onMouseOut(evt);
      },
      false
    );

    this.canvas.addEventListener(
      'mouseenter',
      evt => {
        if (this.mouse.down && !evt.buttons) {
          this.mouse.position = null;
          this.mouse.down = null;
          this.onRender();
          this.$tooltip.detach();
          appEvents.emit('graph-hover-clear');
        }
        $(this.canvas).css('cursor', 'pointer');
      },
      false
    );

    this.canvas.addEventListener(
      'dblclick',
      evt => {
        this.mouse.position = null;
        this.mouse.down = null;
        this.onRender();
        this.$tooltip.detach();
        appEvents.emit('graph-hover-clear');

        console.log('TODO, ZOOM OUT');
      },
      true
    );

    // global events
    appEvents.on(
      'graph-hover',
      event => {
        // ignore other graph hover events if shared tooltip is disabled
        const isThis = event.panel.id === this.panel.id;
        if (!this.dashboard.sharedTooltipModeEnabled() && !isThis) {
          return;
        }

        // ignore if other panels are fullscreen
        if (this.otherPanelInFullscreenMode()) {
          return;
        }

        // Calculate the mouse position when it came from somewhere else
        if (!isThis) {
          if (!event.pos.x || !this.range) {
            // NOTE, this happens when a panel has no data
            // console.log('Invalid hover point', event);
            return;
          }

          const ts = event.pos.x;
          const rect = this.canvas.getBoundingClientRect();
          const elapsed = this.range.to - this.range.from;
          const x = ((ts - this.range.from) / elapsed) * rect.width;

          this.mouse.position = {
            x: x,
            y: event.pos.panelRelY * rect.height,
            yRel: event.pos.panelRelY,
            ts: ts,
            gevt: event,
          };
          //console.log( "Calculate mouseInfo", event, this.mouse.position);
        }

        this.onGraphHover(event, isThis || !this.dashboard.sharedCrosshairModeOnly(), !isThis);
      },
      scope
    );

    appEvents.on(
      'graph-hover-clear',
      (event, info) => {
        this.mouse.position = null;
        this.mouse.down = null;
        this.render();
        this.$tooltip.detach();
      },
      scope
    );

    // scope.$on('$destroy', () => {
    //   this.$tooltip.destroy();
    //   elem.off();
    //   elem.remove();
    // });
  }

  // Utility Functions for time axis
  //---------------------------------

  time_format(range: number, secPerTick: number): string {
    const oneDay = 86400000;
    const oneYear = 31536000000;

    if (secPerTick <= 45) {
      return '%H:%M:%S';
    }
    if (secPerTick <= 7200 || range <= oneDay) {
      return '%H:%M';
    }
    if (secPerTick <= 80000) {
      return '%m/%d %H:%M';
    }
    if (secPerTick <= 2419200 || range <= oneYear) {
      return '%m/%d';
    }
    return '%Y-%m';
  }

  doRequest(options) {
    return this.backendSrv.datasourceRequest(options);
  }

  getTimeResolution(estTimeInterval: number): number {
    const timeIntInSecs = estTimeInterval / 1000;

    if (timeIntInSecs <= 30) {
      return 30 * 1000;
    }

    if (timeIntInSecs <= 60) {
      return 60 * 1000;
    }

    if (timeIntInSecs <= 60 * 5) {
      return 5 * 60 * 1000;
    }

    if (timeIntInSecs <= 60 * 10) {
      return 10 * 60 * 1000;
    }

    if (timeIntInSecs <= 60 * 30) {
      return 30 * 60 * 1000;
    }

    if (timeIntInSecs <= 60 * 60) {
      return 60 * 60 * 1000;
    }

    if (timeIntInSecs <= 60 * 60) {
      return 60 * 60 * 1000;
    }

    if (timeIntInSecs <= 2 * 60 * 60) {
      return 2 * 60 * 60 * 1000;
    }

    if (timeIntInSecs <= 6 * 60 * 60) {
      return 6 * 60 * 60 * 1000;
    }

    if (timeIntInSecs <= 12 * 60 * 60) {
      return 12 * 60 * 60 * 1000;
    }

    if (timeIntInSecs <= 24 * 60 * 60) {
      return 24 * 60 * 60 * 1000;
    }

    if (timeIntInSecs <= 2 * 24 * 60 * 60) {
      return 2 * 24 * 60 * 60 * 1000;
    }

    if (timeIntInSecs <= 7 * 24 * 60 * 60) {
      return 7 * 24 * 60 * 60 * 1000;
    }

    if (timeIntInSecs <= 30 * 24 * 60 * 60) {
      return 30 * 24 * 60 * 60 * 1000;
    }

    return 6 * 30 * 24 * 60 * 60 * 1000;
  }

  roundDate(timeStamp, roundee) {
    timeStamp -= timeStamp % roundee; //subtract amount of time since midnight
    return timeStamp;
  }

  formatDate(d, fmt) {
    const monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    const dayNames = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
    if (typeof d.strftime === 'function') {
      return d.strftime(fmt);
    }

    const r = [];
    let escape = false;
    const hours = d.getHours();
    const isAM = hours < 12;
    let hours12;

    if (hours > 12) {
      hours12 = hours - 12;
    } else if (hours === 0) {
      hours12 = 12;
    } else {
      hours12 = hours;
    }

    for (let i = 0; i < fmt.length; ++i) {
      let c = fmt.charAt(i);

      if (escape) {
        switch (c) {
          case 'a':
            c = '' + dayNames[d.getDay()];
            break;
          case 'b':
            c = '' + monthNames[d.getMonth()];
            break;
          case 'd':
            c = this.leftPad(d.getDate(), '');
            break;
          case 'e':
            c = this.leftPad(d.getDate(), ' ');
            break;
          case 'h': // For back-compat with 0.7; remove in 1.0
          case 'H':
            c = this.leftPad(hours, null);
            break;
          case 'I':
            c = this.leftPad(hours12, null);
            break;
          case 'l':
            c = this.leftPad(hours12, ' ');
            break;
          case 'm':
            c = this.leftPad(d.getMonth() + 1, '');
            break;
          case 'M':
            c = this.leftPad(d.getMinutes(), null);
            break;
          // quarters not in Open Group's strftime specification
          case 'q':
            c = '' + (Math.floor(d.getMonth() / 3) + 1);
            break;
          case 'S':
            c = this.leftPad(d.getSeconds(), null);
            break;
          case 'y':
            c = this.leftPad(d.getFullYear() % 100, null);
            break;
          case 'Y':
            c = '' + d.getFullYear();
            break;
          case 'p':
            c = isAM ? '' + 'am' : '' + 'pm';
            break;
          case 'P':
            c = isAM ? '' + 'AM' : '' + 'PM';
            break;
          case 'w':
            c = '' + d.getDay();
            break;
        }
        r.push(c);
        escape = false;
      } else {
        if (c === '%') {
          escape = true;
        } else {
          r.push(c);
        }
      }
    }

    return r.join('');
  }

  leftPad(n, pad) {
    n = '' + n;
    pad = '' + (pad === null ? '0' : pad);
    return n.length === 1 ? pad + n : n;
  }

  onDateBlur() {
    const newStart = this.modalScope.rangeModel.fromDate;
    const newEnd = this.modalScope.rangeModel.toDate;
    //const newStart = new Date(this['rangeModel'].fromDate);
    //const newEnd = new Date(this['rangeModel'].toDate);

    if (isNaN(newStart.getTime()) || isNaN(newEnd.getTime())) {
      this['errorMessage'] = 'Date range is invalid. Please double check the from and to dates.';
    } else {
      this['errorMessage'] = null;
      this.range.from._d = newStart;
      this.range.to._d = newEnd;
    }
  }

  openDowntimeModal(partialPath: string) {
    const self = this;

    this.modalContainer = document.createElement('div');
    this.modalContainer.id = 'downtime-modal-container';
    document.body.appendChild(this.modalContainer);

    $('#downtime-modal-container').load(partialPath, () => {
      this.modalContainer.style.display = 'flex';

      $('.downtime-modal').on('click', event => {
        event.stopPropagation();
      });

      this.modalContainer.onclick = ev => {
        this.modalDismiss();
      };

      self.$compile(this.modalContainer)(self.modalScope);
    });
  }

  modalDismiss() {
    const modalContainer = document.getElementById('downtime-modal-container');
    modalContainer.style.display = 'none';
    modalContainer.remove();
  }

  showDowntimeDialog(range, pt = null, isSingleClick = false) {
    if (this.panel.opening) {
      return;
    }
    this.$timeout(() => {
      this.panel.opening = false;
    }, 500);
    this.panel.opening = true;
    if (!this.panel.downtimeMeasurement) {
      // this.publishAppEvent('show-modal', {
      //   src: 'public/plugins/downtime-discrete-panel/partials/downtime.needs.configure.html',
      // });

      this.openDowntimeModal('public/plugins/downtime-discrete-panel/partials/downtime.needs.configure.html');

      return;
    }
    this.reasonsDataSource = undefined;
    this.selectedRange = range;

    const tzoffset = new Date().getTimezoneOffset() * 60000; //offset in milliseconds

    this.modalScope = this.$scope.$new(true);
    this.modalScope.modalContainer = this.modalContainer;
    this.modalScope.modalDismiss = this.modalDismiss;
    this.modalScope.range = range;
    this.modalScope.rangeModel = {
      from: new Date(range.from._d - tzoffset)
        .toISOString()
        .slice(0, -5)
        .replace('T', ' '),
      to: new Date(range.to._d - tzoffset)
        .toISOString()
        .slice(0, -5)
        .replace('T', ' '),
      fromDate: range.from._d,
      toDate: range.to._d,
    };
    this.modalScope.useCustomRange = isSingleClick ? 0 : 1;
    this.modalScope.selectStatus = this.selectStatus;
    this.modalScope.backendSrv = this.backendSrv;
    this.modalScope.panel = this.panel;
    this.modalScope.updateStatus = (e, s) => {
      this.selectedStatus = s;
      this.updateSelection(e);
    };
    this.modalScope.currentStatus = this.isSingleTarget ? pt.val : 'Multiple';
    this.modalScope.downtimeLists = this.downtimeLists;
    this.modalScope.onDateBlur = this.onDateBlur;
    this.modalScope.onUpdateDowntimeTags = () =>
      this.onUpdateDowntimeTags(
        this.datasource,
        this.modalScope.rangeModel,
        this.panel.downtimeMeasurement,
        this.selectedStatus,
        this.panel.downtimeCommentField
      );

    this.$timeout(() => {
      $('#rangeModelFrom').datetimepicker({
        format: 'Y/m/d H:i:s',
        value: this.modalScope.rangeModel.from,
        theme: 'dark',
        onChangeDateTime: (dp, $input) => {
          this.modalScope.rangeModel.fromDate = dp;
          this.onDateBlur();
        },
      } as any);
      $('#rangeModelTo').datetimepicker({
        format: 'Y/m/d H:i:s',
        value: this.modalScope.rangeModel.to,
        theme: 'dark',
        onChangeDateTime: (dp, $input) => {
          this.modalScope.rangeModel.toDate = dp;
          this.onDateBlur();
        },
      } as any);
    }, 500);

    if (pt) {
      if (pt.val !== 'OK' && pt.val !== 'Untagged' && pt.val !== 'Unplanned') {
        this.modalScope.selected = true;
        this.selectedStatus = pt.val;
      }

      this.modalScope.downtimeComment = pt.comment;
    }

    // this.publishAppEvent('show-react-modal', {
    //   src: 'public/plugins/downtime-discrete-panel/partials/downtime.prompt.html',
    //   scope: this.modalScope,
    // });

    this.openDowntimeModal('public/plugins/downtime-discrete-panel/partials/downtime.prompt.html');

    if (this.reasonsDataSource) {
      console.log('reasonsDataSource exists');
      if (this.reasonsDataSource.length > 0) {
        this.BuildGroups();
      } else {
        this.modalScope.errorMessage = 'There are no Downtime Reasons available. Please contact our administrators to configure Downtime Reasons.';
      }
    } else if (this.panel.downtimeReasonsApiUrl) {
      this.datasourceSrv.get('public-api').then(ds => {
        const proxyUrl = `${window.location.origin}/api/datasources/${ds.id}/resources`;
        const orgName = this.getOrgNameQueryVar();
        this.$http.get(`${proxyUrl}/publicapi/api/list/DowntimeLists/${this.getSensorVariable()}?${orgName}`).then(
          response => {
            this.reasonsDataSource = response.data;
            this.BuildGroups();
          },
          error => {
            console.log(error);
            this.modalScope.errorMessage =
              'There was an error retrieving downtime reasons. Check the value of Downtime Reasons API URL in the configuration section.';
          }
        );
      });
    } else {
      this.modalScope.errorMessage = 'Configuration is not correct. Please contact our administrators to configure Downtime Reasons.';
    }
  }

  promptInit() {}

  BuildGroups() {
    this.downtimeLists = [];
    this.modalScope.selectedDowntimeList = undefined;
    //console.log('this.reasonsDataSource');
    //console.log(this.reasonsDataSource);
    //console.log(`SensorID:${this.getSensorVariable()}`);
    this.reasonsDataSource.forEach(reason => {
      const parentName = reason.parentName;
      const parentExists = this.downtimeLists.some(p => p.name === parentName);
      if (!parentExists) {
        this.downtimeLists.push({ name: parentName, id: reason.parentId, groups: [] });
      }
      const parent = this.downtimeLists.find(g => g.name === parentName);

      if (reason.isDefault && !this.modalScope.selectedDowntimeList) {
        this.modalScope.selectedDowntimeList = parent;
      }
      if (reason.isSelected) {
        this.modalScope.selectedDowntimeList = parent;
      }

      const grName = reason.type;
      const exists = parent.groups.some(g => g.name === grName);

      if (!exists) {
        parent.groups.push({ name: grName, reasons: [] });
      }
      const group: GroupInterface = parent.groups.find(g => g.name === grName);
      group.reasons.push({ text: reason.text, color: reason.color });
    });

    this.modalScope.downtimeLists = this.downtimeLists;

    if (!this.modalScope.selectedDowntimeList && this.modalScope.downtimeLists.length > 0) {
      this.modalScope.selectedDowntimeList = this.modalScope.downtimeLists[0];
    }

    if (
      this.modalScope.currentStatus !== 'Multiple' &&
      this.modalScope.currentStatus !== 'Unplanned' &&
      this.modalScope.currentStatus !== 'Untagged' &&
      this.modalScope.selectedDowntimeList
    ) {
      //find the list containing this status
      if (!this.searchReasonInList(this.modalScope.selectedDowntimeList, this.modalScope.currentStatus)) {
        this.modalScope.downtimeLists.forEach(list => {
          if (this.searchReasonInList(list, this.modalScope.currentStatus)) {
            this.modalScope.selectedDowntimeList = list;
          }
        });
      }
    }
  }

  searchReasonInList(list, reasonText) {
    if (!list || !list.groups) {
      return false;
    }
    let result = false;
    list.groups.forEach(group => {
      group.reasons.forEach(reason => {
        if (reason.text === reasonText) {
          result = true;
        }
      });
    });
    return result;
  }

  selectStatus(status) {
    this.$parent.updateStatus(status);
  }

  isInRange(ts) {
    return ts >= this.selectedRange.from._d.getTime() && ts <= this.selectedRange.to._d.getTime();
  }

  // get prev tag
  // if tag is not OK, set the end of prev and start of new, else set start of new to next available non OK tag
  // any tag in between is set to current status, keep last status
  // if last status is not OK, set end to current status and start to last status, else set end of current status to the last one before OK status
  onUpdateDowntimeTags(dataSource, range, measurement, status, commentFieldName) {
    this.datasourceSrv.get('public-api').then(ds => {
      const proxyUrl = `${window.location.origin}/api/datasources/${ds.id}/resources`;
      const offset = range.fromDate.getTimezoneOffset();

      const startRangeLocal = range.fromDate.getTime() + offset * 60 * 1000 * -1;
      const endRangeLocal = range.toDate.getTime() + offset * 60 * 1000 * -1;

      this.invokeWriteEndpoint(startRangeLocal, endRangeLocal, status, proxyUrl, offset, dataSource.database, measurement, commentFieldName);
    });
  }

  getVariable(varname: string): any {
    const v = _.find(this.templateSrv.getVariables(), (check: { name: string }) => {
      return check.name === varname;
    });
    return v;
  }

  getSensorVariable() {
    if (this.panel.scopedVars && this.panel.scopedVars['sensor']) {
      return this.panel.scopedVars['sensor'].value;
    }

    return this.getVariable('sensor').current.value;

    let targetValue = '';

    this['variables'].forEach(variable => {
      if (variable.text === 'sensor') {
        targetValue = variable.value;
      }
    });

    return targetValue;
  }

  getOrgNameQueryVar() {
    const value = this.templateSrv.replace('orgName=${__org.name}');
    console.log(value);
    return value;
  }

  invokeWriteEndpoint(start, end, status, proxyUrl, offset, database, measurement, commentFieldName) {
    const payload = {
      from: start,
      to: end,
      targetStatus: status,
      data: this.data[0].changes,
      timezoneOffset: offset * -1,
      database: database,
      measurement: measurement,
      isSingleTarget: this.isSingleTarget,
      comment: this.modalScope.downtimeComment,
      commentFieldName: commentFieldName,
      sensorID: this.getSensorVariable(),
    };

    this.backendSrv
      .post(`${proxyUrl}/publicapi/api/downtime/write`, payload)
      .then(response => {
        this.panel.refresh();

        if (response.success) {
          appEvents.emit('alert-success', ['Success', 'Downtime successfully updated.']);
        } else {
          appEvents.emit('alert-warning', ['Downtime tag update failed.', response.actualResult]);
        }
      })
      .catch(error => {
        appEvents.emit('alert-warning', ['Downtime tag update failed.', error.data]);
      });
  }

  onSingleMouseClick(range, pt) {
    console.log('mouse click');
    this.showDowntimeDialog(range, pt, true);
  }

  updateSelection(event) {
    this.modalScope.selected = true;

    const elements = document.getElementsByClassName('downtime-active');
    for (let i = 0; i < elements.length; i++) {
      elements[i].classList.remove('downtime-active');
    }
    event.currentTarget.classList.add('downtime-active');
  }
}
